import React from 'react';
import ReactDOM from 'react-dom/client';
import './Common/common.css';
import App from './App';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
//import reportWebVitals from './temp/reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <table cellPadding={0} cellSpacing={0} style={{width: '100%', height: '100%'}}>
    <tr>
      <td style={{textAlign: 'center'}}>
        <Header />
      </td>
    </tr>
    
    <tr>
      <td style={{height: '100%', verticalAlign: 'top'}}>
        <App />
      </td>
    </tr>

    <tr>
      <td>
      <Footer />
      </td>
    </tr>
    </table>

  </React.StrictMode>
);


//reportWebVitals(console.log);
