
function LIST(props) {
    const tr_list = [];
    for (let i=0; i < props.data.length; i++) {
        tr_list.push(
            <tr>
                <td>{props.data[i].id}</td>
                <td>{props.data[i].title}</td>
                <td>{props.data[i].body}</td>
            </tr>
        );
    }
    return <tbody>{tr_list}</tbody>;
}

function Free_contents() {
    const data = [ 
        {id:1, title: 'title1', body: 'test body1'}, 
        {id:2, title: 'title2', body: 'test body2'}
    ];
    return (
        <table id="tbl1" cellSpacing="0" cellPadding="0" style={{width:'100%'}}>
            <colgroup>
                <col width="100" />
                <col width="200" />
                <col/>
            </colgroup>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>TITLE</th>
                    <th>CONTENT</th>
                </tr>
            </thead>
            <LIST data={data}></LIST>
        </table>
    );
  }
  
  export default Free_contents;
