// import React from 'react';
 import ReactDOM from 'react-dom/client';
// import '/Common/common.css';
// import Free_contents from '/Board/free_contents';
// //import reportWebVitals from './temp/reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <React.StrictMode>
//     <div style={{fontSize:'20px', fontWeight: 'bold', marginBottom: '20px'}}>free.js</div>
//     <Free_contents />
//     </React.StrictMode>
// );

//reportWebVitals(console.log);

//import React from 'react';
import '../Common/common.css'; // src/Common/common.css

import Free_contents from './Free_contents';

import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Free() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('http://localhost:7101/api/board'); // API URL
        setPosts(response.data);
      } catch (err) {
        console.log(err.message);
        setError(" ");
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (

  <table class="tbl_layout" cellSpacing="0" cellPadding="0" style={{width:'100%', height: '100%'}}>  
  <thead>
  </thead>
  <tbody>
    <tr>
      <td style={{textAlign: "right"}}>LEFT</td>
      <td style={{width: "60%", textAlign: "center"}}>CENTER</td>
      <td style={{textAlign: "left"}}>RIGHT</td>
    </tr>
  </tbody>
  </table>
  /* LIST */
  /*
  <table class="tbl1" cellSpacing="0" cellPadding="0" style={{width:'100%'}}>
  <colgroup>
      <col width="50" />
      <col/>
      <col width="140" />
      <col width="65" />
      <col width="40" />
      <col width="40" />
      <col width="40" />
  </colgroup>
  <thead>
      <tr>
          <th>No</th>
          <th>제 목</th>
          <th>작성</th>
          <th>등록</th>
          <th>추천</th>
          <th>비추</th>
          <th>조회</th>
      </tr>
  </thead>
    {posts.map(post => (
      <tr reguserid={post.reguserid} type="data">
        <td class="list-num" style={{fontSize: "8px"}}>{post.idx}</td>
        <td class="list-title">{post.title}</td>
        <td class="list-name">{post.username}</td>
        <td class="list-num">{post.regdate}</td>
        <td class="list-num">{post.recommend}</td>
        <td class="list-num">{post.notrecommend}</td>
        <td class="list-num">{post.readcnt}</td>
      </tr>
    ))}
  </table>*/
      );
}

export default Free;