import React from 'react';
import '../Common/common.css';

function Header() {
    return (
        <header className="header">
            <nav>
                <ul>
                    <li><a href="/" style={{color: '#000000'}}>ASCIIBOARD</a></li>
                    <li><a href="/Free">Free</a></li>
                    <li><a href="/contact">Contact</a></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;